.menu {
    display: flex;
    flex-direction: column;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .menu-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menu-item:last-child {
    border-bottom: none;
  }
  
  .menu-item:hover {
    background-color: #f0f0f0;
  }
  
  .menu-item h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .menu-item p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #555;
  }